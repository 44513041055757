import React from 'react'
import styles from './back-to-top.scss'
import { FaArrowUp }  from "react-icons/fa";

class BackToTop extends React.Component {
  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.state = {
      showArrow: false
    }
  }

  /**
   * Hook fired when component finished mounting
   * @return {void}
   */
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  
  /**
   * Hook fired when component is going to unmount
   * @return {void}
   */
  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }
  
  /**
   * Goes to top
   * @param  { Event } e
   * @return { void }
   */
  scrollToTop(e) {
    e.preventDefault();
    if ('scroll' in window) {      
      window.scroll({top: 0, left: 0, behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0)
    }
  }
  
  /**
   * Handles scroll event
   * @return {void}
   */
  handleScroll() {
    if (window.scrollY > window.innerHeight / 2 && this.state.showArrow === false) {
      this.setState({
        showArrow: true
      })
    } else if (window.scrollY < window.innerHeight / 2 && this.state.showArrow === true) {
      this.setState({
        showArrow: false
      })
    }
  }
  
  render() {    
    return (
      <a className={`back-to-top-container ${this.props.className ? this.props.className : ''}`} 
        style={{display: this.state.showArrow ? 'initial' : 'none'}}
        href='#' onClick={this.scrollToTop}
        title="Back To Top">
        <FaArrowUp className='back-to-top-arrow' title="Back To Top Arrow" />
      </a>
    )
  }
}

export default BackToTop
